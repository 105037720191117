/*
* Mobile Navigation
*/

$(document).ready(function() {
	$(document).on("click", "#mobilemenu", function() {
		$("#mobilemenu_show").slideToggle(150,"linear");
		$("#mobilemenu").toggleClass("topnavigation_mobile_menu_active");
	});
});

